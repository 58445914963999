import React, { useState, useEffect } from 'react';
import { useApiManager } from 'lib/ApiContext';
import { useNavigation } from '../../lib/NavigationContext'
import PeopleIcon from '@mui/icons-material/People';
import { Grid, Box, Stack, MenuItem, Breadcrumbs, Typography, Avatar, Divider, Button, Chip, emphasize, Backdrop, CircularProgress } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import Paper from '@mui/material/Paper';
import { styled, useTheme } from '@mui/material/styles';
import { StyledBreadcrumb, StyledTableCell, StyledTableRow, StyledTableRow2, StyledTableCell2 } from 'components/CPStyles';
import Employee from 'pages/employeesPages/Employee';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableFooter from '@mui/material/TableFooter';
import TableRow from '@mui/material/TableRow';
import { deepPurple } from '@mui/material/colors';
import { Dashboard } from '@mui/icons-material';

const dateOptions = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric"
  };

const PayRollPage = ({ employee, fromDate, toDate, Payment }) => {

    const api = useApiManager();
    const theme = useTheme();
    const { navigateTo, navigateBack } = useNavigation();
    const [payroll, setPayroll] = useState(null);
    const [userData, setUserData] = useState(null);
    const [company, setCompany] = useState(null);
    const [isPaid, setIsPaid] = useState(false);
    const formatter = new Intl.NumberFormat('es-CR', { style: 'currency', currency: 'CRC' });

    useEffect(() => {
        api.GetCurrentUserCompany().then(r => { setUserData(r); api.GetCompany(r.companyID).then(r2 => setCompany(r2)) });
        if(Payment === undefined)
        {
            api.CalculatePayroll(employee.Cedula, fromDate, toDate).then(
                r =>
                    setPayroll(r)
            );
        }
        else {
            setIsPaid(true);
            api.CalculatePayroll(Payment.Cedula, Payment.FechaInicio, Payment.FechaFinal).then(
                r =>
                    setPayroll(r)
            );
        }
    }, []);

    const getDevengos = () => {
        let devengos = [];
        if (payroll && (employee || Payment)) {
            devengos = [...devengos,
            { name: "Salario Base", cantidad: undefined, monto: formatter.format(payroll.Devengos.SalarioBase), total: formatter.format(payroll.Devengos.SalarioBase) }
            ];

            if (payroll.Devengos.HorasExtraCantidad > 0)
            {
                devengos = [...devengos,
                    { name: "Extra por Dia Feriado", cantidad: payroll.Devengos.HorasExtraCantidad + " h", monto: formatter.format(payroll.Devengos.ValorHora), total: formatter.format(payroll.Devengos.HorasExtra) }
                    ];
            }
            if (payroll.Devengos.HorasExtraMedioCantidad > 0)
            {
                devengos = [...devengos,
                    { name: "Horas Extra", cantidad: payroll.Devengos.HorasExtraMedioCantidad + " h", monto: formatter.format(payroll.Devengos.ValorHora*1.5), total: formatter.format(payroll.Devengos.HorasExtraMedio) }
                    ];
            }
            if (payroll.Devengos.HorasExtraDobleCantidad > 0)
            {
                devengos = [...devengos,
                    { name: "Horas Extra Doble", cantidad: payroll.Devengos.HorasExtraDobleCantidad + " h", monto: formatter.format(payroll.Devengos.ValorHora*2), total: formatter.format(payroll.Devengos.HorasExtraDoble) }
                    ];
            }
            if (payroll.Devengos.OtrosAjustes.TotalOtrosAjustes > 0)
            {
                for(let i = 0; i < payroll.Devengos.OtrosAjustes.Details.length; i++)
                {
                    devengos = [...devengos,
                        { name: payroll.Devengos.OtrosAjustes.Details[i].Razon, cantidad: undefined, monto: formatter.format(payroll.Devengos.OtrosAjustes.Details[i].Monto), total: formatter.format(payroll.Devengos.OtrosAjustes.Details[i].Monto) }
                    ];
                }
            }

        }
        

        return devengos;
    }

    const getDeducciones = () => {
        let deducciones = [];

        if (payroll && (employee || Payment)) {
            deducciones = [...deducciones,
            { name: "CCSS", cantidad: payroll.Deducciones.CCSS.PorcentajeTrabajador + "%", monto: formatter.format(payroll.Deducciones.CCSS.TotalTrabajador), total: formatter.format(payroll.Deducciones.CCSS.TotalTrabajador) },
            ];

            if (payroll.Deducciones.Incapacidades.TotalDays > 0)
            {
                deducciones = [...deducciones,
                    { name: "Incapacidad", cantidad: payroll.Deducciones.Incapacidades.TotalDays + " d", monto: formatter.format(payroll.Devengos.ValorHora*4), total: formatter.format(payroll.Deducciones.Incapacidades.TotalDeductible) },
                ];
            }

            if (payroll.Deducciones.PermisoSinGozeHoras > 0)
            {
                deducciones = [...deducciones,
                    { name: "Permiso sin goce de salario", cantidad: payroll.Deducciones.PermisoSinGozeHoras + " h", monto: formatter.format(payroll.Deducciones.PermisoSinGoze), total: formatter.format(payroll.Deducciones.PermisoSinGoze) },
                ];
            }

            if (payroll.Deducciones.OtrasDeducciones.TotalDeductions > 0)
            {
                deducciones = [...deducciones,
                    { name: "Otras deducciones", cantidad: undefined, monto: formatter.format(payroll.Deducciones.OtrasDeducciones.TotalDeductions), total: formatter.format(payroll.Deducciones.OtrasDeducciones.TotalDeductions) }
                ];
            }

            if (payroll.Deducciones.Renta > 0)
            {
                deducciones = [...deducciones,
                    { name: "Renta", cantidad: undefined, monto: formatter.format(payroll.Deducciones.Renta), total: formatter.format(payroll.Deducciones.Renta) }
                ];
            }

        }

        return deducciones;
    }

    const handleNavigateBack = (target) => {
        if (employee) {
            navigateTo(<Employee cedula={employee.Cedula} />);
        }
        else {
           //navigateTo(<Dashboard/>)
           navigateBack();
        }
    }

    const PayEmployee = (target) =>  {
        setIsPaid(true);
        api.DoPayment(employee ? employee.Cedula : Payment.Cedula, fromDate, toDate).then(
            r => {
                setPayroll(r);
            }
        );
    }

    const ResendPayment = (target) =>  {
        api.ResendPayment(employee ? employee.Cedula : Payment.Cedula, fromDate, toDate).then(
            r => {
                setPayroll(r);
            }
        );
    }

    return <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Breadcrumbs aria-label="breadcrumb">
            <StyledBreadcrumb
                component="a"
                href="#"
                onClick={() => handleNavigateBack("dashboard")}
                label="Empleado"
                icon={<PeopleIcon fontSize="small" />}
            />
            <StyledBreadcrumb
                label="Desglose de Pago"
            />
        </Breadcrumbs>
        <Paper elevation={3} sx={{ textAlign: 'left', padding: 1, margin: 2 }}>
            <Typography variant="h4" color="primary">
                {"Desglose de Pago"}
            </Typography>
            <br />
            {payroll == null ?
                <Stack direction="column" spacing={2}>
                    <Skeleton variant="rectangular" width={210} height={60} />
                    <Skeleton variant="rectangular" width={150} height={30} />
                </Stack>

                :
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={6}>
                        <Paper elevation={1} sx={{ margin: 1, padding: 1 }}>
                            <Stack direction="column" spacing={1}>
                                <Typography variant="h6" color="primary" >
                                    EMPRESA
                                </Typography>
                                <Typography variant="body" >
                                    {company ? company.Nombre : "..."}
                                </Typography>
                                {company ? <Typography variant="body"> <Typography variant="body" fontWeight="bold" >Domicilio: </Typography>{company.Direccion}</Typography> : <Skeleton variant="rectangular" width={150} height={30} />}
                                {company ? <Typography variant="body"> <Typography variant="body" fontWeight="bold" >Ced </Typography>{company.CedulaJuridica}</Typography> : <Skeleton variant="rectangular" width={150} height={30} />}
                                {company ? <Typography variant="body"> <Typography variant="body" fontWeight="bold" >Código Cuenta Cotizacion: </Typography>{" "}</Typography> : <Skeleton variant="rectangular" width={150} height={30} />}
                            </Stack>
                        </Paper>
                    </Grid>
                    <Grid item xs={6}>
                        <Paper elevation={1} sx={{ margin: 1, padding: 1 }} >
                            <Stack direction="column" spacing={1}>
                                <Typography variant="h6" color="primary" >
                                    TRABAJADOR/A
                                </Typography>
                                <Typography variant="body" >
                                    {employee ? employee.Nombre + " " + employee.Apellidos : Payment.Nombre}
                                </Typography>
                                <Typography variant="body" ><Typography variant="body" fontWeight="bold" >DNI:</Typography></Typography>
                                <Typography variant="body" ><Typography variant="body" fontWeight="bold" >Número de afiliación a la S.S: </Typography> { employee ? employee.Cedula : Payment.Cedula}</Typography>
                                <Typography variant="body" ><Typography variant="body" fontWeight="bold" >Categoría o grupo profesiona:</Typography> { employee ? employee.Cargo : Payment.Cargo}</Typography>
                            </Stack>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper elevation={3}>
                            <Grid container rowSpacing={1} margin={1} padding={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                            <Grid item >
                                <Typography variant='body1' fontWeight="bold">De:</Typography>
                            </Grid>
                            <Grid item >
                                <Typography variant='body1'>{new Date(fromDate+"T00:00:00").toLocaleDateString("es-CR", dateOptions)}</Typography>
                            </Grid>
                            <Grid item >
                                <Typography variant='body1' fontWeight="bold">Hasta:</Typography>
                            </Grid>
                            <Grid item >
                                <Typography variant='body1'>{new Date(toDate+"T00:00:00").toLocaleDateString("es-CR", dateOptions)}</Typography>
                            </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 700 }} aria-label="desglose de pago">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>Devengos</StyledTableCell>
                                        <StyledTableCell align="right">Horas</StyledTableCell>
                                        <StyledTableCell align="right">Monto</StyledTableCell>
                                        <StyledTableCell align="right">Totales</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {getDevengos().map((row) => (
                                        <StyledTableRow key={row.name}>
                                            <StyledTableCell component="th" scope="row">
                                                {row.name}
                                            </StyledTableCell>
                                            <StyledTableCell align="right">{row.cantidad}</StyledTableCell>
                                            <StyledTableCell align="right">{row.monto}</StyledTableCell>
                                            <StyledTableCell align="right">{row.total}</StyledTableCell>

                                        </StyledTableRow>
                                    ))}
                                    <StyledTableRow key={"TotalDevengo"}>
                                        <StyledTableCell component="th" scope="row">
                                            <Typography variant='body' fontWeight="bold">TOTAL DEVENGADO</Typography>
                                        </StyledTableCell>
                                        <StyledTableCell align="right"> </StyledTableCell>
                                        <StyledTableCell align="right"> </StyledTableCell>
                                        <StyledTableCell align="right">{payroll ? formatter.format(payroll.Devengos.TotalDevengado) : " - "}</StyledTableCell>
                                    </StyledTableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 700 }} aria-label="desglose de deducciones">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>Deducciones</StyledTableCell>
                                        <StyledTableCell align="right">Horas</StyledTableCell>
                                        <StyledTableCell align="right">Monto</StyledTableCell>
                                        <StyledTableCell align="right">Totales</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {getDeducciones().map((row) => (
                                        <StyledTableRow key={row.name}>
                                            <StyledTableCell component="th" scope="row">
                                                {row.name}
                                            </StyledTableCell>
                                            <StyledTableCell align="right">{row.cantidad}</StyledTableCell>
                                            <StyledTableCell align="right">{row.monto}</StyledTableCell>
                                            <StyledTableCell align="right">{row.total}</StyledTableCell>

                                        </StyledTableRow>
                                    ))}
                                    <StyledTableRow key={"TotalDeductions"}>
                                        <StyledTableCell component="th" scope="row">
                                            <Typography variant='body' fontWeight="bold">TOTAL A DEDUCIR</Typography>
                                        </StyledTableCell>
                                        <StyledTableCell align="right"> </StyledTableCell>
                                        <StyledTableCell align="right"> </StyledTableCell>
                                        <StyledTableCell align="right">{payroll ? formatter.format(payroll.Deducciones.TotalDeducciones) : " - "}</StyledTableCell>
                                    </StyledTableRow>

                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid item xs={12}>
                        <Table sx={{ minWidth: 700 }} aria-label="totales">
                            <TableBody>
                                <StyledTableRow >
                                    <StyledTableCell sx={{ backgroundColor: "darkgreen", color: 'white' }}> <Typography variant='body' fontWeight="bold">LIQUIDO A PERCIBIR</Typography></StyledTableCell>
                                    <StyledTableCell align="right" sx={{ backgroundColor: "darkgreen", color: 'white' }}>&nbsp;</StyledTableCell>
                                    <StyledTableCell align="right" sx={{ backgroundColor: "darkgreen", color: 'white' }}>&nbsp;</StyledTableCell>
                                    <StyledTableCell align="right" sx={{ backgroundColor: "darkgreen", color: 'white' }}>{payroll ? formatter.format(payroll.TotalTrabajadorNeto) : " - "}</StyledTableCell>
                                </StyledTableRow>
                            </TableBody>
                        </Table>
                    </Grid>
                    <Grid item xs={12}>
                        <Stack direction="row">
                        <Button disabled={isPaid} variant='contained' sx={{margin:2}} onClick={ () => PayEmployee() }>{isPaid? "Pagado" : "Hacer Pago"}</Button>
                        {isPaid? <Button variant='contained' sx={{margin:2}} onClick={ () => ResendPayment() }>Re-enviar Colilla</Button> : <Box>&nbsp;</Box>}
                        </Stack>
                    </Grid>
                </Grid>
            }
             
        </Paper>
    </Box>
}


export default PayRollPage;