import React, { useState, useEffect } from 'react';
import { useApiManager } from 'lib/ApiContext';
import { styled, useTheme } from '@mui/material/styles';
import Title from 'components/Title';
import EmployeeList from 'components/EmployeeList';
import Box from '@mui/material/Box';
import { Grid, Button, Paper, InputBase, IconButton, ToggleButton } from '@mui/material';
import Employee from './Employee';
import NewEmployee from './NewEmployee';
import SearchIcon from '@mui/icons-material/Search';
import {useNavigation} from '../../lib/NavigationContext'
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';

import PersonOffIcon from '@mui/icons-material/PersonOff';
import { blue, grey } from '@mui/material/colors';

const Employees = () => {
  const api = useApiManager();
  const [employees, setEmployees] = useState({Items:[]});
  const [showInactive, setShowInactive] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const { navigateTo } = useNavigation();
  
  useEffect(() => {
    api.GetEmployees().then(r => setEmployees(r));
  }, []);

  const onCreateEmployee = (cedula) => {
    navigateTo(<NewEmployee />);
  }
  const onSelectedEmployee = (cedula) => {
    navigateTo(<Employee cedula={cedula} />);
  }
  const onSearchValueChange = (e) => {
    const { value } = e.target;
    setSearchValue(value);
  }

  const getEmployeesFiltered = () => {
    if (searchValue != "")
    {
      return employees.Items.filter(v => (v.Activo == true || v.Activo != showInactive) && [v.Cedula, v.Nombre, v.Apellidos, v.Email, v.Cargo].join(" ").toUpperCase().indexOf(searchValue.toUpperCase()) !== -1);
    }
    return employees.Items.filter(v => (v.Activo == true || v.Activo != showInactive));
  }

  return <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
    <Box sx={{ position: 'sticky', top: 0, zIndex: 1100, backgroundColor: 'background.paper' }}>
      <Grid container spacing={1} justifyContent="space-between">
        <Grid item xs={6}><Title>Empleados</Title></Grid>
        <Grid item xs={6} display="flex" justifyContent="flex-end">
          <Fab color="primary" aria-label="Agregar Empleado"  onClick={onCreateEmployee}>
              <AddIcon />
            </Fab>
        </Grid>
      </Grid>
      <Paper
        component="form"
        sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}
      >
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder="Buscar"
          inputProps={{ 'aria-label': 'buscar empleado' }}
          value={searchValue}
          onChange={onSearchValueChange}
        />
        <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
          <SearchIcon />
        </IconButton>
        <ToggleButton
          value="check"
          selected={showInactive}
          onChange={() => {
            setShowInactive(!showInactive);
          }}
        >
          {!showInactive ?
          <PersonOffIcon  sx={{ color: grey[400] }} />:
          <PersonOffIcon  sx={{ color: blue[700] }} />
          }
        </ToggleButton>
      </Paper>
    </Box>
    <EmployeeList employees={getEmployeesFiltered()} onSelected={onSelectedEmployee} />
  </Box>
}

export default Employees;